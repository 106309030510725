import React, { useState } from "react";
import axios from 'axios';
import Header from "./Header"
// import firebase from "firebase";

class JoinWaitlist extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          value: '',
          priority_number: '',
          email_submitted: '',
          total_users: '',
          ref_link: '',
          error_info: '',
          error_msg: "",
      };
  
      this.handleChange = this.handleChange.bind(this);
      this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
      // firebase.analytics().logEvent("join_waitlist_page")
    }
  
    handleChange(event) {
      this.setState({value: event.target.value});
    }
  
    handleSubmit(event) {
      event.preventDefault();
      console.log('A name was submitted: ' + this.state.value);
      var body = {
          email: this.state.value,
          api_key: process.env.GATSBY_WAITLIST_API_KEY,
          referral_link: window.location.href
      };
  
      axios.post('https://www.getwaitlist.com/waitlist', body)
        .then((response) => {
            var registered_email = response['data']['registered_email']
            var current_priority = response['data']['current_priority']
            var total_waiters_currently = response['data']['total_waiters_currently']
            var referral_link = response['data']['referral_link']
            this.setState({
                email_submitted: registered_email,
                priority_number: current_priority,
                total_users: total_waiters_currently,
                ref_link: referral_link
            });

            window.location.replace(`https://getwaitlist.com/status?email=${registered_email}&waitlist_id=${process.env.GATSBY_WAITLIST_API_KEY}`)
        })
        .catch((error) => {
            this.setState({error_msg: 'Some error occured unfortunately. Try joining again?'})
            console.log(error.response)
        });
  
    }
  
        render() {
        return (

            <div className="flex flex-col min-h-screen overflow-hidden">

            {/*  Site header */}
      
            {/*  Page content */}
            <main className="flex-grow">
      
              <section className="bg-gradient-to-b from-gray-100 to-white">
                <div className="max-w-6xl mx-auto px-4 sm:px-6">
                  <div className="pt-32 pb-12 md:pt-40 md:pb-20">
      
                    {/* Page header */}
                    <div className="max-w-3xl mx-auto text-center pb-9">
                      <h1 className="h1 mb-4 text-4xl font-extrabold leading-tight tracking-tighter" data-aos="zoom-y-out">Join Waitlist</h1>
                      <p className="pt-4 text-center text-lg text-gray-600 mb-8" data-aos="zoom-y-out" data-aos-delay="100">Just enter your email 
                      below and we'll let you know when you get early access to our background generator and other tools.</p>
                      <p className="error_msg">{this.error_msg}</p>
                    </div>
      
                    {/* Form */}
                    <div className="max-w-sm mx-auto" data-aos="zoom-y-out" data-aos-delay="150">
      
                      <form>

                        <div className="flex flex-wrap -mx-3 mb-4">
                          <div className="w-full px-3">
                            <label className="block text-gray-800 text-sm font-medium mb-1" htmlFor="email">Email <span className="text-red-600">*</span></label>
                            <input id="email" type="email" className="form-input w-full text-gray-800 appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" onChange={this.handleChange}
                            name="email" placeholder="Enter your email address" required />
                          </div>
                        </div>
 
                        <div className="flex flex-wrap -mx-3 mt-6">
                          <div className="w-full px-3">
                          <button className="btn text-white bg-blue-600 hover:bg-blue-700 w-full button pt-24 pb-24 py-3 shadow-lg font-medium inline-flex items-center justify-center border border-transparent rounded leading-snug transition duration-150 ease-in-out" type="button"
                          loadingText="Adding you to the waitlist..."
                            onClick={this.handleSubmit}><span className="py-2">Join Waitlist</span></button>
                                                      <div className="text-sm text-gray-500 text-center mt-3">
                            We won't spam, we promise.
                          </div>
                          </div>

                        </div>

                      </form>
      

                    </div>
      
                  </div>
                </div>
              </section>
      
            </main>
      
          </div>
        );
  }

}

export default JoinWaitlist
